

































import Vue from "vue";
// Import Swiper Vue.js components
import {Swiper, Navigation, Thumbs} from "swiper/swiper.esm";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/thumbs/thumbs.min.css";
import "./swiper-style.css";
import {httpClient} from "@/services";
import {MUT_TRUCKS} from "@/store/mutations/types";
import {urlMedia} from "@/enviorment";

// install Swiper modules
Swiper.use([Navigation, Thumbs]);

export default Vue.extend({
  name: "galery",
  data() {
    return {
      loadingImage: true,
      thumbsSwiper: null,
      galery: null,
      galery2: null,
      images: [],
    };
  },
  computed: {
    imageList(): string[] {
      let result: string[] = [];

      console.log(this.images)

      this.images.forEach((image: any) => {
        const extension = image.pictureUrls[0].split(".")[1];
        const extensions = "png jpg jpeg";

        if (extensions.includes(extension)) {
          result.push(`${urlMedia}camion/${image._id}/${image.pictureUrls[0]}`);
        }
      });

      return result;
    },
  },
  mounted() {
    httpClient.get("galery?limit=-1&skip=0").then((response) => {
      this.images = response.data.data;
    });

    (this as any).galery = new Swiper(".product-galery", {
      spaceBetween: 4,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      },
    });
    (this as any).galery2 = new Swiper(".product-galery2", {
      zoom: true,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: this.galery,
      },
    });
  },
});
